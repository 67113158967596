@import "../../../../theme/clr_var.scss";

.table_responsive {
  overflow-x: scroll;
  white-space: nowrap;
  table {
    border: 1px solid $secondaryColor;
    border-radius: 8px !important;
    tr {
      th,
      td {
        color: white;
        border: none !important;
        vertical-align: baseline;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $boderColor;
      }
    }
  }
}
.pagination {
  justify-content: center;
  .page-item.active {
    .page-link {
      background-color: #a73e82;
      border-color: #a63f83;
    }
  }
  li {
    a.page-link {
      background-color: transparent;
      color: $white;
      border: 1px solid $boderColor;
      &:hover {
        background-color: #a73f83;
        border-color: #a73f83;
      }
      &:active {
        background-color: #a73f83;
        border-color: #a73f83;
      }
    }
  }
}
p.factory_data {
  text-align: center;
}
