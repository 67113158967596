@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0710 !important;
  color: #fff !important;
}

@media (min-width: 1200px) {
  .common-container.container {
    max-width: 1235px !important;
  }
}

button:focus {
  outline: none;
  outline: none;
}

.ui.active.transition.visible.page.dimmer {
  z-index: 9999;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
label.custom-control-label {
  cursor: pointer;
}
a.canvasjs-chart-credit {
  display: none;
}

.betTimeUl {
  list-style-type: none;
}
.betTimeUl li {
  color: #fff;
}

.Defiindexpopup {
  background: #341016;
}

.Defiindexpopup:hover {
  background: #d1332d;
}

.dark-theme {
  background-color: #0d0710 !important;
}

.light-theme {
  background-color: #fff !important;
  .inverse-menu .navbar-nav .nav-link {
    color: #000 !important;
  }
  .inverse-menu a.wallet-btn.nav-link {
    background: rgba(209, 51, 45, 0.9);

    color: #fff !important;
  }
  .container-border {
    background-color: #fff;
  }
  .heading {
    color: #000;
  }
  .sub-heading {
    color: #000;
  }
  p.home-text {
    color: #000;
  }
  .card .defi-button {
    background-color: #fff;

    color: #000;
  }
  .container .card {
    background-color: #fff;
  }
  .chart-card .price-bar p {
    color: #000;
  }
  .chart-card {
    background-color: #fff;
  }
  .Container-fluid .footer-text a {
    color: #000;
  }
  .deficoin-border {
    background-color: #fff;
  }
  .Defiindexpopup {
    background: #f7f7f7;
    h4 {
      color: #000;
    }
  }
  .def-container .def-tabs {
    background: #fff;
  }
  .def-container .def-tabs .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  .def-container .def-tabs .nav-tabs .nav-link.active {
    color: #000;
  }
  .def-container .def-tabs .tab-table thead th {
    border-bottom: 1px solid #f0ebeb;
  }
  .def-container .def-tabs .tab-table td {
    border-bottom: 1px solid #f0ebeb;
  }
  .def-container .def-tabs .tab-table .table-btn .dropdown-toggle {
    color: #000;
  }
  .container .staked-top-row h4 {
    color: #000;
  }
  .connect_metamask {
    color: #000;
  }
  .container .staked-border {
    background-color: #fff;
  }
  .inverse-menu .navbar-nav .dropdown-menu {
    background-color: #fff;
  }
  .text-white {
    color: #000 !important;
  }
  .swap-container {
    background-color: #fff;
  }
  .swap-container .swap-row-2 .bottom-right-col .bottom-right p {
    color: #000;
  }
  .select::after {
    background: #fff;
  }
  .swap-container
    .swap-row-2
    .bottom-left-col
    .swap-bottom-row
    .bottom-left
    .input-area
    .form-input {
    background-color: #fff;
  }
  .select {
    background: #fff;
  }
  .swap-container
    .swap-row-2
    .bottom-left-col
    .swap-bottom-row
    .bottom-left
    .input-area {
    background-color: #fff;
  }
  .swap-container
    .swap-row-2
    .bottom-left-col
    .swap-bottom-row
    .bottom-left
    .input-area
    .input-icon {
    background-color: #fff;
  }
  .swap-container
    .swap-row-2
    .bottom-left-col
    .swap-bottom-row
    .bottom-left
    h4 {
    color: #000;
  }
  .white-text {
    color: #000 !important;
  }
  .stake_top_row .right-col .portfolio_sec .total_amount h2 {
    color: #000;
  }
  .custom-control-label {
    color: #000;
  }
  .stake_top_row .bottom-left .input-area .form-input {
    background-color: #fff;
  }
  .def-container .def-tabs .tab-table td {
    color: #000;
  }
  .defi-index-container {
    background-color: #fff;
  }
  .defi-card h4,
  .defi-card p {
    color: #000;
  }
  .Container-fluid .footer-text p {
    color: #000;
  }
  .no_bet {
    color: #000;
  }
  .def-container .def-tabs .tab-table .dropdown-menu .dropdown-item {
    color: #000;
  }
  .def-container .def-tabs .tab-table .dropdown-menu {
    background-color: #ffff;
  }
  .modal-meta .modal-title {
    color: #000;
  }
  .modal-meta .meta-address {
    background: #f1f1f1;
    color: #000;
  }
  .modal-home span {
    color: #000;
  }
  .modal-meta .meta-btn {
    background: #dedede;
  }
  .modal-meta .custom-control-label {
    color: #fff;
  }
  .modal-home {
    background: #fff !important;
  }
  .bottom-line p {
    color: #000;
  }
  .home-popup.modal-body {
    background: #fff;
  }
  .modal-home .modal-header {
    color: #000;
    background: #fff;
  }
  a.header-logo {
    background-image: url(./Images/inverse_logo-black.png);
  }
  .container .staked-second-row table tbody .stake-amount,
  .container .staked-last-row table tbody .stake-amount {
    color: #000;
  }

  .container .stake-third-row .text-reward h4 {
    color: #000;
  }

  .bg-defoult {
    background-color: #fff;
  }
  .bg-defoult a {
    color: #000 !important;
    border-bottom: 1px solid #00000059;
  }

  .bg-defoult a.active {
    color: #fff !important;
  }
  .sidebar .bg-defoult li a:hover {
    color: #fff !important;
  }
  .header {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }
  .header .navbar-light .navbar-nav .nav-link {
    color: #000;
  }
  .admin-form .form-control,
  .admin-form select.form-control {
    background-color: #fff;
    color: #000 !important;
  }
  .admin-form label.form-label.col-form-label {
    color: #000;
  }
  .panel-slide {
    background: #ccc;
  }
  div#submenu-panel,
  div#submenu-panel2,
  div#panel2 {
    background: #504e4e85;
  }
  .container .staked-second-row table tbody .revoke-button,
  .container .staked-last-row table tbody .revoke-button {
    background-color: #fff;
  }
  .admin-form .form-control:focus {
    background-color: #fff;
  }

  .container .staked-second-row table tbody .won-button,
  .container .staked-last-row table tbody .won-button {
    background-color: #2fca74 !important;
  }
  .inverse-menu .icon_right img {
    filter: brightness(0) invert(1);
  }

  .navbar-light .navbar-toggler {
    border-color: transparent !important;
    background: #000;
    text-align: center;
    padding: 7px;
  }
  .navbar-toggler span {
    margin-left: 0;
  }
  @media (max-width: 767px) {
    .inverse-menu .navbar-collapse {
      align-items: center;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.defi-popup .stake-now-btn:hover {
  color: #fff;
}

.ui.segment {
  padding: 0 !important;
}

.table td,
.table th {
  padding: 12px 20px !important;
  font-size: 14px;
}

.container .staked-second-row table tbody .loss-button,
.container .staked-last-row table tbody .loss-button {
  background-color: #da0000 !important;
}

.top-col-heading {
  width: 35%;
}

.popup-card .submit-button {
  display: grid !important;
}

.popup-card .submit-button h3 {
  color: #d23b2e;
  text-align: center;
}

.popup-card .submit-button .input-value {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.timerDiv {
  font-size: 25px;
  color: #d4433d;
  margin: 0;
}

.image-card.card {
  cursor: pointer;
}

.bottom-line ul li {
  color: #fff;
}

.light-theme .bottom-line ul li {
  color: #000;
}

.bottom-line ul {
  padding: 0 30px !important;
}

.stakeInfo {
  color: #000;
  text-align: center;
  margin-top: 13px;
  font-size: 11px;
  font-weight: 500;
  font-style: italic !important;
  padding: 0;
}

.bottomHeading {
  width: 100%;
  color: #fec135 !important;
  font-style: italic !important;
  text-align: right;
  font-size: 12px;
}

.admin_popup {
  background-color: #ad3e7e;
}
