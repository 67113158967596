@import "../../../../theme/clr_var.scss";

/*------------bsc sec----------------------*/

.admin-row{
  height: 545px;
  overflow-y: scroll;
.bsc_sec {
  width: 100%;
  border-radius: 10px;
  border: 1px solid $boderColor;
  border-radius: 4px;
  padding: 0;
  margin: 0 10px 5px;
  h2 {
    background: $boderColor;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // padding: 6px;
  }

  p {
    margin: 0 0 5px 0;
    font-size: 16px;
  }
  input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }

  .planet_bar {
    width: 100%;
    cursor: pointer;
    background-color: $boderColor;
    font-size: 20px;
    padding: 20px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // margin-top: 10px !important;
  }

  input[type="text"] {
    border-radius: 10px;
    padding: 12px;
    border-bottom: none;
    font-size: 14px;
  }
  .funds {
    margin: 20px !important;
    border-radius: 10px;
    // padding: 20px;
    p {
      font-size: 14px;
      margin: 10px 0 20px ;
    }
  }
}
}